import React from 'react';
import RootElement from './src/components/root-element';
import { ThemeProvider } from './src/components/ContextProviders/ThemeProvider';
import { GlobalStyle } from './src/components/Layout/sharedStyles/globalStyles';
import '@fontsource-variable/montserrat';

export const wrapPageElement = ({ element }) => (
  <>
    <GlobalStyle />
    <ThemeProvider>{element}</ThemeProvider>
  </>
);


export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== 'production') {
  return null;
  }
  const pagePath = location ? location.pathname + location.search + location.hash : undefined;
  setTimeout(() => {
  if (typeof window.gtag === 'function') {
      window.gtag('event', 'page_view', { page_path: pagePath });
  }
  }, 100);
  return true;
};

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>;
};