import { createGlobalStyle } from 'styled-components';
import { easeOutTiming } from './animationStyles';
 
export const GlobalStyle = createGlobalStyle`
    :root {
        --globalContainer: 800px;
        --globalContainerHeader: 1210px;
        --articleContainer: 700px;/* maquette 700 - 2*30px paddingLF = 740 px*/
        --globalPaddingLr: 30px; /* 30px */
        --globalPaddingTb: 50px; /* 80px maquette*/
        --globalPaddingT: 80px; /* 80px maquette*/
        --globalPaddingB: 80px; /* 80px maquette*/
        --defaultRadius: 0.625rem; /* 10px */
        --gapSmall: 0.625rem; /* 10px */
        --gapRegular: 1.25rem; /* 20px */
        --gapL: 20px; /* 20px */
        --gapXL: 30px; 
        --gapXXL: 35px; /* 60px */
        --borderRegular: 2px;
        --borderSmall: 1px;
        --defaultStack: 'Montserrat Variable', -apple-system, BlinkMacSystemFont, "Helvetica", "Helvetica Neue", "Arial", sans-serif;
        --defaultCodeStack:  ui-monospace, "Cascadia Mono", "Segoe UI Mono", "Ubuntu Mono", "Roboto Mono", Menlo, Monaco, Consolas, monospace;
        --headingXXL: 3.25rem; /* 52px */
        --headingXL: 3.125rem;  /* 42px */
        --headingL: 1.875rem; /* 32px */
        --headingM: 1.625rem; /* 26px */
        --headingS: 1.375rem; /* 24px */
        --baseXL: 1.25rem; /* 20px */
        --baseL: 1.125rem; /* 18px */
        --baseM: 1rem; /* 16px */
        --baseS: .815rem; /* 14px */
        --baseMMobile: calc(var(--baseM) * 1.1);
        --baseSMobile: calc(var(--baseS) * 1.1);
        --headingsLineHeight: 1.1;
        --bodyLineHeight: 26px;
    }

    *, *::before, *::after {
        box-sizing: border-box;
    }

    html {
        font-size: 100%;
        font-family: var(--defaultStack);
        margin: 0;
        padding: 0;
        line-height: var(--bodyLineHeight);
        background: var(--backgroundColor);
    }

    [dir=rtl] {
      font-size: 110%;
    }

    main {padding-bottom: 70px;}

    .lightTheme {
        --primaryColor: #ED1C24; //maquette rouge
        --headingsColor: #221616; 
        --baseTextColor: #221616; //maquette noir
        --dividerColor: #E2E2E2;
        --markBackgroundColor: #FDFFB4;
        --markTextColor: #1E1E1E;
        --inlineCodeTextColor: #4D4D4D;
        --inlineCodeBackgroundColor: #DBEFFF;
        --backgroundColor: #FFFFFF;
        --backgroundTransparentColor: rgba(255, 255, 255, 0);
        --backgroundColorAlt: #FFFFFF;
        --codeBlockBackgroundColor: #181B22;
    }

    .darkTheme {
        --primaryColor: #ED1C24; //maquette rouge
        --headingsColor: #221616; 
        --baseTextColor: #221616; //maquette noir
        --dividerColor: #E2E2E2;
        --markBackgroundColor: #FDFFB4;
        --markTextColor: #1E1E1E;
        --inlineCodeTextColor: #4D4D4D;
        --inlineCodeBackgroundColor: #DBEFFF;
        --backgroundColor: #FFFFFF;
        --backgroundTransparentColor: rgba(255, 255, 255, 0);
        --backgroundColorAlt: #FFFFFF;
        --codeBlockBackgroundColor: #181B22;
    }

    #gatsby-focus-wrapper {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        footer { 
            margin-top:auto;
        }
    }

    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-weight: 500;
        color: var(--baseTextColor);
        margin: 0;
        padding: 0;
    }

    h1, h2, h3, p {
        margin: 0;
        padding: 0;
    }

    ul, ol {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    button {
        cursor: pointer;
        margin: 0;
        padding: 0;
        appearance: none;
        border: none;
        background: none;
    }

    button,
    input,
    select,
    textarea {
        font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        margin: 0;
    }

    a { 
        text-decoration: none;
        background-color: transparent;
       
    }

    

    b, strong {
        font-weight: 700;
    }

    address, time {
        font-style: normal;
    }

    input[type="text"], input[type="password"], textarea {
        border: none;
        outline: none;
      }

    /* Classes */

    .activeClassLink {
        color: var(--primaryColor) !important;
        cursor: default;
    }
    
    .classicButton {
     
        box-sizing: border-box;
       
        display: inline-flex;
        width: fit-content;
        padding: 5px 25px;
        margin-top:50px;
        margin-bottom:25px;
        justify-content: flex-end;
        align-items: center;
        gap: 5px;
        border-radius: 20px 0px;
        color:#fff;
        border: 1px solid #fff;
        font-weight:700;
        font-size:16px;
        letter-spacing: 0.48px;

        &:hover {
            outline:  2px solid #fff;
            outline-offset: -2px;
        }

        &:active {
            color:var(--primaryColor);
        }

        @media (max-width: 768px) {
            font-size: 14px;
        }
    }

    .classicButtonOutline {
        font-weight: 500;
        letter-spacing: .48px;
        position:relative;
        text-decoration: none!important;
        display: inline-flex;
        padding: 10px 25px;
        margin-top:45px;
        margin-bottom:80px;
        margin-left:auto;
        margin-right:auto;
        justify-content: flex-end;
        align-items: center;
        gap: 5px;
        border-radius: 20px 0px;
        color:#221616;
        border: 1px solid #221616;
        
        &:hover {
          border: 1px solid var(--primaryColor);
          background-color: var(--primaryColor)!important;
          color:white;
          svg path { stroke:white;}
        }

       
    }

    .classicButtonOutline.noMarginTop {
        margin-top: 10px;    
    }

    /* LOGO */
    @media (max-width: 833px) {
        #logo {
            width:170px;
        }
    }


    /* RGPD INTRO */
    
    .rgpd--container {
        z-index:100;
        font-size:0.9rem;
    }
    .rgpd-acceptance-theme .rgpd--banner.mini {  
        z-index:100;
        background:white;
    }
    .rgpd-acceptance-theme .rgpd--banner.full {
        z-index:100;
        background:white;
    }

    .rgpd--banner {
        margin-left:2rem;
        margin-right:2rem;
    }

    .rgpd--cookie-description { 
        line-height: 10px;
        font-style:normal!important;
        color:#787676;
        padding-bottom:10px;
    }
    .rgpd--footer { 
        flex-wrap:wrap;
    }

    .rgpd--btn.rgpd--btn.all,
    .rgpd--btn.rgpd--btn.save,
    .rgpd--btn.rgpd--btn.none,
    .rgpd--btn.rgpd--btn.some,
    .rgpd--btn.rgpd--btn.choose
     {
        text-decoration: none!important;
        display: inline-flex;
        padding: 10px 25px;
       
        justify-content: flex-end;
        align-items: center;
        gap: 5px;
        border-radius: 20px 0px;
        color:#221616!important;
        border: 1px solid #221616!important;
        background-color: white!important;
        &:hover {
          border: 1px solid var(--primaryColor);
          background-color: var(--primaryColor)!important;
          color:white!important;
          svg path { stroke:white;}
        }
    }

    
    
    `;
