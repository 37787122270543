import React, { Fragment, useEffect } from 'react';
import { Script } from 'gatsby';



function simplyReadOneCookie(key, mandatory) {
    return (typeof window !== 'undefined') && localStorage.getItem(
      `rgpd-acceptance_${key}_${mandatory ? 'mandatory' : 'consent'}`
    )
  }

const RootElement = ({ children }) => {

 //  console.log("read cookie google-analytics : ", simplyReadOneCookie('google-analytics',0))
  return ( /*((typeof window !== 'undefined') && simplyReadOneCookie('google-analytics',0) === "true")  ? */
           
    <Fragment>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GA_MEASUREMENT_ID}`}
        strategy="post-hydrate"
        forward={[`gtag`]}
        key="gtag1"
      />
      <Script
        key="gtag2"
        id="gtag-config"
        strategy="post-hydrate"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          window.gtag = function gtag(){ window.dataLayer.push(arguments);}
          gtag('js', new Date()); 
          gtag('config', '${process.env.GATSBY_GA_MEASUREMENT_ID}', { send_page_view: false })`
        }}
      />
      {children}
    </Fragment> /*: 
    children */
        
  )
  
};

export default RootElement;